import commonHelper from '@/app/utility/common.helper.utility';
import workdayCalendarDetails from './workdayCalenderDetails';
export default {
  name: 'workdayCalendar',
  components: {
    workdayCalendarDetails
  },
  watch: {
    currentPage: function() {
      this.getWorkdayCalendarDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getWorkdayCalendarDetails();
    }
  },
  data() {
    return {
      unsubscribe: null,
      showModal: false,
      loader: false,
      editMode: false,
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      calendarName:null,
      selectedCalenderDetails:null,
      workdayCalendarData: [],
      workdayCalendarFields: [
        {
          key: 'workday_calendar_name'
        },
        {
          key: 'shift_hour'
        },
        {
          key: 'shift_days'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ],
      defaultValue: {
        value: null,
        text: null
      },
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.selectedCalenderDetails=null;
          this.showModal = true;
        }
      }
    });
  },
  methods: {
    getWorkdayCalendarDetails() {
      const payload = {
        _limit: this.perPage,
        _page: this.currentPage - 1,
        workday_calendar_name: this.calendarName
      };
      this.loader = true;
      this.$store
        .dispatch('workdayCalendar/getWorkdayCalendarDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.workdayCalendarData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
        });
    },
    rowSelected(rowData) {
      this.selectedCalenderDetails=rowData;
      this.showModal = true;
    },
    clearFilters() {
      this.workdayCalendarData = [];
      this.totalRows = null;
      this.calendarName=null;
    },
    showHideModal() {
      this.showModal = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
