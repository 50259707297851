import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'workdayCalendarDetails',
  props: ['selectedCalenderDetails'],
  components: {
    DatePicker
  },
  watch: {
    currentPage: function() {
      this.getWorkdayCalendarDetailsById();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getWorkdayCalendarDetailsById();
    }
  },
  data() {
    return {
      unsubscribe: null,
      showModal: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      editMode: false,
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      workHdrId: 0,
      calendarName: null,
      shiftHours: null,
      shiftDays: null,
      startDate: null,
      endDate: null,
      validateEndate: null,
      addMode: true,
      checkedWeekDay: [
        { day: 'Mon', isChecked: false },
        { day: 'Tue', isChecked: false },
        { day: 'Wed', isChecked: false },
        { day: 'Thu', isChecked: false },
        { day: 'Fri', isChecked: false },
        { day: 'Sat', isChecked: false },
        { day: 'Sun', isChecked: false }
      ],
      dateRange: null,
      workdayCalendarData: [],
      initialworkdayCalendarData: [],
      workdayCalendarFields: [
        { key: 'workday_date' },
        { key: 'day_name' ,label:'Workday Day'},
        { key: 'is_holiday' },
        { key: 'holiday_reason' },
        { key: 'shift_hour' }
      ],
      isValidated: true,
      shiftDetailsData: [
        {
          workday_shift_id: 0,
          shift_name: null,
          shift_start_time: null,
          shift_end_time: null,
          is_default: null
        }
      ],
      shiftDetailsFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'shift_name'
        },
        {
          key: 'shift_start_time'
        },
        {
          key: 'shift_end_time'
        },
        {
          key: 'is_default'
        }
      ],
      showShiftDetailsModal: false
    };
  },
  mounted() {
    if (this.selectedCalenderDetails) {
      this.addMode = false;
      this.setWorkdayCalendarDetails(this.selectedCalenderDetails);
    } else {
      this.addMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save') {
          this.addAndEditWorkdayCalendarDetails();
        }
      }
    });
  },
  methods: {
    validateShiftDays() {
      if (this.shiftDays >= 0 && this.shiftDays <= 7) {
        this.shiftDays = Math.floor(this.shiftDays);
      } else {
        this.shiftDays = 7;
      }
      for (let i = 0; i < 7; i++) {
        this.checkedWeekDay[i].isChecked = false;
      }
      this.daysOffValidation();
    },
    daysOffValidation() {
      let count = 0;
      this.checkedWeekDay.forEach(item => {
        if (item.isChecked) {
          count++;
        }
      });
      if (count >= 7 - this.shiftDays) {
        this.isValidated = false;
      } else {
        this.isValidated = true;
      }
    },
    setWorkdayCalendarDetails(item) {
      this.workHdrId = item.workday_hdr_id;
      this.calendarName = item.workday_calendar_name;
      this.shiftHours = item.shift_hour;
      this.shiftDays = item.shift_days;
      this.startDate = item.start_date;
      this.endDate = item.end_date;
      this.validateEndate = item.end_date;
      this.checkedWeekDay[0].isChecked = item.mon;
      this.checkedWeekDay[1].isChecked = item.tue;
      this.checkedWeekDay[2].isChecked = item.wed;
      this.checkedWeekDay[3].isChecked = item.thu;
      this.checkedWeekDay[4].isChecked = item.fri;
      this.checkedWeekDay[5].isChecked = item.sat;
      this.checkedWeekDay[6].isChecked = item.sun;
    },
    addAndEditWorkdayCalendarDetails() {
      const payload = {
        fms_workday_hdr_details: {
          workday_hdr_id: this.workHdrId,
          workday_calendar_name: this.calendarName,
          shift_hour: this.shiftHours,
          shift_days: this.shiftDays,
          start_date: this.startDate,
          end_date: this.endDate,
          mon: this.checkedWeekDay[0].isChecked,
          tue: this.checkedWeekDay[1].isChecked,
          wed: this.checkedWeekDay[2].isChecked,
          thu: this.checkedWeekDay[3].isChecked,
          fri: this.checkedWeekDay[4].isChecked,
          sat: this.checkedWeekDay[5].isChecked,
          sun: this.checkedWeekDay[6].isChecked
        }
      };
      this.loader = true;
      this.$store
        .dispatch('workdayCalendar/addAndEditWorkdayCalendarDetails', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getWorkdayCalendarDetailsById() {
      const workDayDateFrom = this.dateRange
        ? commonHelper.formattedDate(new Date(this.dateRange[0]))
        : null;
      const workDayDateTo = this.dateRange
        ? commonHelper.formattedDate(new Date(this.dateRange[1]))
        : null;
      const requestParams = {
        workday_hdr_id: this.workHdrId
      };
      const searchParams = {
        _limit: this.perPage,
        _page: this.currentPage - 1,
        workday_date_from: workDayDateFrom,
        workday_date_to: workDayDateTo
      };
      const payload = {
        pathVariables: requestParams.workday_hdr_id,
        searchParams: searchParams
      };
      this.loader = true;
      this.$store
        .dispatch('workdayCalendar/getWorkdayCalendarDetailsById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.workdayCalendarData = response.data.data.page;
            this.initialworkdayCalendarData = JSON.parse(
              JSON.stringify(response.data.data.page)
            );
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
        });
    },
    saveCalendarDetails() {
      const newArr = [];
      for (let i = 0; i < this.workdayCalendarData.length; i++) {
        if (
          this.workdayCalendarData[i].workday_dtl_id ==
          this.initialworkdayCalendarData[i].workday_dtl_id
        ) {
          if (
            this.workdayCalendarData[i].is_holiday !==
              this.initialworkdayCalendarData[i].is_holiday ||
            this.workdayCalendarData[i].holiday_reason !==
              this.initialworkdayCalendarData[i].holiday_reason ||
            this.workdayCalendarData[i].shift_hour !==
              this.initialworkdayCalendarData[i].shift_hour
          ) {
            newArr.push({
              workday_dtl_id: this.workdayCalendarData[i].workday_dtl_id,
              is_holiday: this.workdayCalendarData[i].is_holiday,
              holiday_reason: this.workdayCalendarData[i].holiday_reason,
              shift_hour: this.workdayCalendarData[i].shift_hour
            });
          }
        }
      }
      const payload = {
        fms_workday_calendar_details: newArr
      };
      this.loader = true;
      this.$store
        .dispatch('workdayCalendar/saveCalendarDetails', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.editMode = false;
            this.getWorkdayCalendarDetailsById();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearFilters() {
      this.totalRows = 0;
      this.dateRange = null;
      this.workdayCalendarData = null;
    },
    showHideModal() {
      this.showModal = false;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.validateEndate);
    },
    showHideShiftDetails(flag) {
      this.showShiftDetailsModal = flag;
    },
    getShiftDetailsById(flag) {
      this.showShiftDetailsModal = flag;
      this.loader = true;
      this.$store
        .dispatch('workdayCalendar/getShiftDetailsById', this.workHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data.length > 0) {
            this.shiftDetailsData = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    saveDetails() {
      this.showShiftDetailsModal = false;
      const shiftDetails = [];
      this.shiftDetailsData.map(item => {
        shiftDetails.push({
          workday_shift_id: item.workday_shift_id,
          shift_name: item.shift_name,
          shift_start_time: item.shift_start_time,
          shift_end_time: item.shift_end_time,
          is_default: item.is_default
        });
      });
      const payload = {
        workday_hdr_id: this.workHdrId,
        requestParams: {
          fms_workday_shift_details: shiftDetails
        }
      };
      this.loader = true;
      this.$store
        .dispatch('workdayCalendar/saveShiftDetails', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.showAlert = true;
            this.responseMsg = response.data.message;
            this.getShiftDetailsById();
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
        });
    },
    addNewDetailsRow() {
      if (this.editMode) {
        this.shiftDetailsData.push({
          workday_shift_id: 0,
          shift_name: null,
          shift_start_time: null,
          shift_end_time: null,
          is_default: null
        });
      }
    },
    removeDetailsRow(index) {
      if (this.editMode) {
        this.shiftDetailsData.splice(index, 1);
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
